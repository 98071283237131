import React from 'react'
import PageSeo from '../../components/SEO'
import LiveDemo from "../../components/LiveDemo";

function DemoPage() {
  return (
      <>
        <PageSeo pageTitle="Live Demo" />
        <LiveDemo />
      </>
  )
}

export default DemoPage
