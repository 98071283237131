import React from "react";
import PricingNavbar from "../Pricing/PricingNavbar/PricingNavbar";
import InfluencerFooter from "../LandingSubComponents/InfluencerFooter/InfluencerFooter";
import useSiteMetadata from "../SiteMetaData";
import {openPopupWidget} from "react-calendly";
import './LiveDemo.css'
import DemoSection from "../LandingSubComponents/DemoSection";

const LiveDemo = () => {
  const {dashboardRegisterUrl, calendlyDemoUrl} = useSiteMetadata()

  const bookDemo = () => openPopupWidget({ url: calendlyDemoUrl })

  return(
      <div
          className="FAQCol"
      >
        <PricingNavbar />
        <div className="container p-0">
          <div className="columns is-multiline is-mobile">
            <div className="column is-12">
              <h3 className="has-text-centered is-size-2-desktop is-size-3-tablet is-size-3-mobile has-text-black has-text-weight-bold">
                Live Demo
              </h3>
              <h4 className={"has-text-centered is-size-6-desktop is-size-7"}>
                Influencerbit starts by adding a field for Instagram Username to any existing form on your website. Like in the example below:
              </h4>
            </div>
            <div className="column is-12">
              <iframe
                  className={"frame-component"}
                  src="https://brand.influencerbit.com/livedemo"
                  title="Influencerbit Live Demo"
              />
            </div>
          </div>
        </div>
        <div className={"container has-text-centered"}>
          <div className={"section"}>
            <button
                className={"button is-primary mx-4 is-uppercase is-medium has-text-weight-bold"}
                onClick={()=>window.open(dashboardRegisterUrl, "_blank")}
            >
              start 14 day free trial
            </button>
            <button
                className={"button is-primary is-outlined is-uppercase is-medium has-text-weight-bold"}
                onClick={() => bookDemo()}
            >
              BOOK A DEMO WITH US
            </button>
          </div>
          <div>
          </div>
        </div>
        <DemoSection />
        <div className="mt-6">
          <InfluencerFooter />
        </div>
      </div>
  )
}

export default LiveDemo